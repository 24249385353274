import React, { useRef, useEffect } from 'react';
import { extend } from "@react-three/fiber"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader"
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import helvetiker from "three/examples/fonts/helvetiker_regular.typeface.json"

const Text3DIntroduction2 = () => {
    //Greeting
  var greeting;
  const current_time = new Date();
  const morning_start = new Date().setHours(6, 0, 0);     // 6:00 AM
  const afternoon_start = new Date().setHours(12, 0, 0); // 12:00 PM
  const evening_start = new Date().setHours(18, 0, 0);   // 6:00 PM

  if (current_time >= morning_start && current_time < afternoon_start) {
  greeting = "Good morning,";
  } else if (current_time >= afternoon_start && current_time < evening_start) {
  greeting = "Good afternoon,";
  } else {
  greeting = "Good evening,";
  }

  extend({ TextGeometry })
  
  const helvetikerRegular = new FontLoader().parse(helvetiker)
  
  const textOptionsH1 = {
    font: helvetikerRegular,
    size: 0.8,
    height: 0.2,
  }
  const textOptions = {
    font: helvetikerRegular,
    size: 1.5,
    height: 0.2,
  }
  
  return(
    <>
    <mesh position={[10.5, 5, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={["< h1 >", textOptionsH1]}/>
      <meshLambertMaterial attach='material' color={'rgb(150, 200, 250)'}/>
    </mesh>
    <mesh position={[10.5, 3, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={[`  About Me`, textOptions]}/>
      <meshLambertMaterial attach='material' color={'rgb(100, 190, 255)'}/>
    </mesh>

    <mesh position={[10.5, 1.8, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={["< / h1 >", textOptionsH1]}/>
      <meshLambertMaterial attach='material' color={'rgb(150, 200, 250)'}/>
    </mesh>
    <mesh position={[10.5, 0, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={[`    While I'm not developing new application \n     ideas, you can find me on the Gundam \n     Battle Operation2.\n\n    I also love hiking in the mountains, even \n    though I have Acrophobia. `, textOptionsH1]}/>
      <meshLambertMaterial attach='material' color={'rgb(255, 255, 255)'}/>
    </mesh>
    </>
  )

}

export default Text3DIntroduction2;