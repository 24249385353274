import React from "react";
import ReactDOM from "react-dom/client";
// import EarthTwo from "./EarthTwo";
import EarthThree from "./EarthThree";



ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <EarthThree></EarthThree>
  </React.StrictMode>
); 