import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, extend } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
extend({ TextGeometry });
import Text3DIntroduction from './Text3DIntroduction';
import Text3DIntroduction2 from './Text3DIntroduction2';
import Text3DIntroduction3 from './Text3DIntroduction3';
import Text3DIntroduction4 from './Text3DIntroduction4';
import Face1 from './Face1.svg';
import Face2 from './Face2.svg';
import Face3 from './Face3.svg';
import Face4 from './Face4.svg';
import Face5 from './Face5.svg';
import Face6 from './Face6.svg';
extend({ Canvas });
import { SelectiveBloom, EffectComposer } from '@react-three/postprocessing'
// import { EffectComposer } from '@react-three/postprocessing'
// import { SelectiveBloom } from '@react-three/postprocessing'


const EarthThree = () => {

  const rotationSpeed = -0.00005;


  const SparkleStars = () => {
    const group = useRef();
    const numDots = 500;
    const dotsPositions = new Array(numDots)
      .fill()
      .map(() => new THREE.Vector3());

    function getRandomInSphere(radius) {
      const u = Math.random();
      const v = Math.random();
      const theta = 2 * Math.PI * u;
      const phi = Math.acos(2 * v - 1);
      const x = radius * Math.sin(phi) * Math.cos(theta);
      const y = radius * Math.sin(phi) * Math.sin(theta);
      const z = radius * Math.cos(phi);
      return [x, y, z];
    }

    for (let i = 0; i < numDots; i++) {
      const arr = getRandomInSphere(300);
      dotsPositions[i].x = arr[0];
      dotsPositions[i].y = arr[1];
      dotsPositions[i].z = arr[2];
    }

    useFrame(()=>{
      animateSparkleDotsBG(group.current);
    })

    return (
      <group ref={group}>
        {dotsPositions.map((position, index) => (
          <points key={index} position={position}>
            <sphereGeometry args={[0.1, 8, 8]} />
            <pointsMaterial color="rgb(255, 255, 255)" size={0.35} />
          </points>
        ))}
      </group>
    );
  };


  function Cube1({ onHover, ...props }) {
    const ref = useRef()
    ///
    const textureLoader = new THREE.TextureLoader();
  
    const textureSize = 320; 
  
    const face1Texture = textureLoader.load(Face1, (texture) => {
      texture.image.width = textureSize;
      texture.image.height = textureSize;
      texture.needsUpdate = true;
    });
    
    const face2Texture = textureLoader.load(Face2, (texture) => {
      texture.image.width = textureSize;
      texture.image.height = textureSize;
      texture.needsUpdate = true;
    });

    const face3Texture = textureLoader.load(Face3, (texture) => {
      texture.image.width = textureSize;
      texture.image.height = textureSize;
      texture.needsUpdate = true;
    });

    const face4Texture = textureLoader.load(Face4, (texture) => {
      texture.image.width = textureSize;
      texture.image.height = textureSize;
      texture.needsUpdate = true;
    });

    const face5Texture = textureLoader.load(Face5, (texture) => {
      texture.image.width = textureSize;
      texture.image.height = textureSize;
      texture.needsUpdate = true;
    });

    const face6Texture = textureLoader.load(Face6, (texture) => {
      texture.image.width = textureSize;
      texture.image.height = textureSize;
      texture.needsUpdate = true;
    });
  
    // Create two materials for Face1 and Face2
    const face1Material = new THREE.MeshPhongMaterial({ map: face1Texture, transparent: true });
    const face2Material = new THREE.MeshPhongMaterial({ map: face2Texture, transparent: true });
    const face3Material = new THREE.MeshPhongMaterial({ map: face3Texture, transparent: true  });
    const face4Material = new THREE.MeshPhongMaterial({ map: face4Texture, transparent: true  });
    const face5Material = new THREE.MeshPhongMaterial({ map: face5Texture, transparent: true  });
    const face6Material = new THREE.MeshPhongMaterial({ map: face6Texture, transparent: true  });
      ///
    useEffect(() => {
      onHover(ref.current);
    }, [onHover]);


      // Animation state variables
    function generateRandomInteger(input) {
      // Generate a random decimal between 0 and 1
      const randomDecimal = Math.random();

      // Convert the random decimal to an integer between 1 and 3
      const randomInteger = Math.floor(randomDecimal * 3) + 1;
      return randomInteger;
    }

    let dirNumber = 1;
    let RotateAccX = 0;
    let RotateAccY = 0;
    let RotateAccZ = 0;


    useFrame((state, delta) => {
      if (dirNumber === 1) {
        // Tumble down 90 degrees (rotate around the X-axis)
        ref.current.rotation.x -= Math.PI / 100;
        RotateAccX +=  Math.PI / 100;
        if (Math.abs(RotateAccX) >= Math.abs(Math.PI / 2)) {
          RotateAccX = 0;
          dirNumber = generateRandomInteger(dirNumber);
        }
      } 
      else if (dirNumber === 2) {
        ref.current.rotation.z += Math.PI / 100;
        RotateAccZ +=  Math.PI / 100;
        if (Math.abs(RotateAccZ) >= Math.abs(Math.PI / 2)) {
          RotateAccZ = 0;
          dirNumber = generateRandomInteger(dirNumber);
        }
      }
      else if (dirNumber === 3) {
        ref.current.rotation.y += Math.PI / 100;
        RotateAccY +=  Math.PI / 100;
        if (Math.abs(RotateAccY) >= Math.abs(Math.PI / 2)) {
          RotateAccY = 0;
          dirNumber = generateRandomInteger(dirNumber);
        }
      }
    });

    return (
      <mesh ref={ref} {...props} material={[face1Material, face2Material, face3Material, face4Material, face5Material, face6Material]}>
        <boxGeometry args={[9, 9, 9]} />
      </mesh>
    )
  }

  function Cube2({ ...props }) {
    const ref = useRef()
 
    useFrame((state, delta) => (ref.current.rotation.x = ref.current.rotation.y += delta))
    return (
      <mesh ref={ref} {...props} >
        <boxGeometry args={[6, 6, 6]}/>
        <meshStandardMaterial color="black" />
      </mesh>
    )
  }
  
  function CubeContainer() {
    const [hovered, onHover] = useState(null)
    const selected = hovered ? [hovered] : undefined
    const lightRef2 = useRef()
    const lightRef3 = useRef()
  
    return (
      <>
        <spotLight position={[-30, 70, -30]} angle={0.33} penumbra={1} ref={lightRef2} distance={10}/>
        <pointLight position={[0, 0, 0]} ref={lightRef3} distance={10}/>
        <Cube1 onHover={onHover} position={[0, 0, 0]} />
        <EffectComposer autoClear={false}>
          <SelectiveBloom
            selection={selected}
            intensity={1.5}
            luminanceThreshold={0.01}
            luminanceSmoothing={0.025}
            lights={[lightRef2, lightRef3]}
          />
        </EffectComposer>
      </>
    )
  }
  

  const animateSparkleDotsBG = (sparkleDots) => {
    const time = Date.now() * 0.0000005;
    sparkleDots.rotation.y = time;
    sparkleDots.rotation.z = time;
    sparkleDots.children.forEach((dot, index) => {
      let r = Math.sqrt( dot.position.x * dot.position.x + dot.position.z * dot.position.z )
      dot.position.x = Math.cos(time * (1 + 0.3 * index)) * r;
      dot.position.z = Math.sin(time * (1 + 0.3 * index)) * r;
    });
  };


  const animateSparkleDots = (sparkleDots) => {
    const time = Date.now() * 0.00035;
    sparkleDots.rotation.y = time;
  };
  const animateSparkleDots2 = (sparkleDots) => {
    const time = Date.now() * 0.000225;
    sparkleDots.rotation.y = time;
  };
  const animateSparkleDots3 = (sparkleDots) => {
    const time = Date.now() * 0.00015;
    sparkleDots.rotation.y = time;
  };
  const animateSparkleDots4 = (sparkleDots) => {
    const time = Date.now() * 0.0003;
    sparkleDots.rotation.y = time;
  };


  const SparkleDots = () => {
    const group = useRef();
    const numDots = 234;
    const dotsPositions = new Array(numDots)
      .fill()
      .map(() => new THREE.Vector3());

    for (let i = 0; i < numDots; i++) {
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * 7 + 40;

      dotsPositions[i].x = Math.cos(angle) * distance;
      dotsPositions[i].y = (Math.random() - 0.5) * 2.5;
      dotsPositions[i].z = Math.sin(angle) * distance;
    }

    useFrame(() => animateSparkleDots(group.current));

    return (
      <group ref={group}>
        {dotsPositions.map((position, index) => (
          <group key={index} position={position}>
            <points>
              <sphereGeometry args={[0.03, 4, 4]} />
              <pointsMaterial color={'rgb(0, 255, 255)'} size={0.05} />
            </points>
          </group>
        ))}
      </group>
    );
  };
  const SparkleDots2 = () => {
    const group = useRef();
    const numDots = 234;
    const dotsPositions = new Array(numDots)
      .fill()
      .map(() => new THREE.Vector3());

    for (let i = 0; i < numDots; i++) {
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * 7 + 40;

      dotsPositions[i].x = Math.cos(angle) * distance;
      dotsPositions[i].y = (Math.random() - 0.5) * 2.5;
      dotsPositions[i].z = Math.sin(angle) * distance;
    }

    useFrame(() => animateSparkleDots2(group.current));

    return (
      <group ref={group}>
        {dotsPositions.map((position, index) => (
          <group key={index} position={position}>
            <points>
              <sphereGeometry args={[0.05, 8, 8]} />
              <pointsMaterial color={'rgb(0, 255, 220)'} size={0.08} />
            </points>
          </group>
        ))}
      </group>
    );
  };
  const SparkleDots3 = () => {
    const group = useRef();
    const numDots = 234;
    const dotsPositions = new Array(numDots)
      .fill()
      .map(() => new THREE.Vector3());

    for (let i = 0; i < numDots; i++) {
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * 7 + 40;

      dotsPositions[i].x = Math.cos(angle) * distance;
      dotsPositions[i].y = (Math.random() - 0.5) * 2.5;
      dotsPositions[i].z = Math.sin(angle) * distance;
    }

    useFrame(() => animateSparkleDots3(group.current));

    return (
      <group ref={group}>
        {dotsPositions.map((position, index) => (
          <group key={index} position={position}>
            <points>
              <sphereGeometry args={[0.05, 8, 8]} />
              <pointsMaterial color={'rgb(0, 255, 195)'} size={0.11} />
            </points>
          </group>
        ))}
      </group>
    );
  };
  const SparkleDots4 = () => {
    const group = useRef();
    const numDots = 234;
    const dotsPositions = new Array(numDots)
      .fill()
      .map(() => new THREE.Vector3());

    for (let i = 0; i < numDots; i++) {
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * 7 + 40;

      dotsPositions[i].x = Math.cos(angle) * distance;
      dotsPositions[i].y = (Math.random() - 0.5) * 2.5;
      dotsPositions[i].z = Math.sin(angle) * distance;
    }

    useFrame(() => animateSparkleDots4(group.current));

    return (
      <group ref={group}>
        {dotsPositions.map((position, index) => (
          <group key={index} position={position}>
            <points>
              <sphereGeometry args={[0.05, 8, 8]} />
              <pointsMaterial color={'rgb(0, 200, 255)'} size={0.065} />
            </points>
          </group>
        ))}
      </group>
    );
  };

  const Board = ({ position, rotation, children }) => {
    const meshRef = useRef();
    // The radius of the circular orbit around the center sphere
    const orbitRadius = 42;

    useFrame(() => {
        meshRef.current.position.x = Math.cos(rotationSpeed * Date.now()) * orbitRadius;
        meshRef.current.position.z = Math.sin(rotationSpeed * Date.now()) * orbitRadius;
        const targetPosition = new THREE.Vector3(0, 8, 0);
        meshRef.current.lookAt(targetPosition);
    });

    return (
      <group ref={meshRef} position={position}>
        <mesh>
          <boxGeometry args={[22, 15, 0.8]} />
          <meshBasicMaterial color="rgb(20, 60, 70)" opacity={0.8} transparent />
        </mesh>
        {children}
      </group>
    );
  };
  const Board2 = ({ position, children }) => {
    const meshRef = useRef();
    const orbitRadius = 42;
    const angle = Math.PI / 2; // 90-degree angle

    useFrame(() => {
        const time = Date.now() * rotationSpeed;
        meshRef.current.position.x = Math.cos(time + angle) * orbitRadius;
        meshRef.current.position.z = Math.sin(time + angle) * orbitRadius;
        const targetPosition = new THREE.Vector3(0, 8, 0);
        meshRef.current.lookAt(targetPosition);
    });


    return (
      <group ref={meshRef} position={position}>
        <mesh>
          <boxGeometry args={[25, 15, 0.8]} />
          <meshBasicMaterial color="rgb(20, 60, 70)" opacity={0.8} transparent />
        </mesh>
        {children}
      </group>
    );
  };
  const Board3 = ({ position, children }) => {
    const meshRef = useRef();
    const orbitRadius = 42;
    const angle = Math.PI; // 180-degree angle

    useFrame(() => {
        const time = Date.now() * rotationSpeed;
        meshRef.current.position.x = Math.cos(time + angle) * orbitRadius;
        meshRef.current.position.z = Math.sin(time + angle) * orbitRadius;
        const targetPosition = new THREE.Vector3(0, 8, 0);
        meshRef.current.lookAt(targetPosition);
    });
    

    return (
      <group ref={meshRef} position={position}>
        <mesh>
          <boxGeometry args={[25, 15, 0.8]} />
          <meshBasicMaterial color="rgb(20, 60, 70)" opacity={0.8} transparent />
        </mesh>
        {children}
      </group>
    );
  };
  const Board4 = ({ position, children }) => {
    const meshRef = useRef();
    const orbitRadius = 42;
    const angle = Math.PI * 3 / 2; // 270-degree angle

    useFrame(() => {
        const time = Date.now() * rotationSpeed;
        meshRef.current.position.x = Math.cos(time + angle) * orbitRadius;
        meshRef.current.position.z = Math.sin(time + angle) * orbitRadius;
        const targetPosition = new THREE.Vector3(0, 8, 0);
        meshRef.current.lookAt(targetPosition);
    });
    

    return (
      <group ref={meshRef} position={position}>
        <mesh>
          <boxGeometry args={[30, 15, 0.8]} />
          <meshBasicMaterial color="rgb(20, 60, 70)" opacity={0.8} transparent />
        </mesh>
        {children}
      </group>
    );
  };


  return (
    <>
      <div style={{ position: 'absolute', color: 'white', top: '40px', left: '40px', zIndex: '999999', display: 'flex', fontFamily: "menlo, cursive" }}>
        <a>{"<Ching-Yuan Lai (Eric)>"}</a>
      </div>
      <Canvas
        style={{ background: 'black', height: '100vh' }}
        camera={{ position: [5, 5, -70], fov: 75, rotation: [0, 0, 0] }}
        gl={{ antialias: true }}
      >
        <ambientLight intensity={0.3} />
        <directionalLight color="rgb(255, 255, 200)" intensity={1} position={[25, 100, -50]} />
        <CubeContainer />

        <SparkleDots />
        <SparkleDots2 />
        <SparkleDots3 />
        <SparkleDots4 />
        <SparkleStars />
        <group>
          <Board position={[0, 8, -40]}>
            <Text3DIntroduction />
          </Board>
        </group>
        <group>
          <Board2 position={[40, 8, 0]}>
            <Text3DIntroduction4 />
          </Board2>
        </group>
        <group>
          <Board3 position={[0, 8, 40]}>
            <Text3DIntroduction3 />
          </Board3>
        </group>
        <group>
          <Board4 position={[-40, 8, 0]}>
            <Text3DIntroduction2 />
          </Board4>
        </group>
        <OrbitControls
          // enableZoom={false}
          enablePan={false}
          minPolarAngle={Math.PI / 2 - 0.07} // Limit the rotation upwards (from the vertical axis)
          maxPolarAngle={Math.PI / 2 - 0.07} // Limit the rotation downwards (from the vertical axis)
        />
      </Canvas>
      <div style={{ position: 'absolute', color: 'white', bottom: '40px', left: '40px', zIndex: '999999', display: 'flex', fontFamily: "menlo, cursive" }}>
      <a>{"</Ching-Yuan Lai (Eric)>"}</a>
      </div>
    </>
  );
};

export default EarthThree;
