import React, { useRef, useEffect, useState } from 'react';
import { extend } from "@react-three/fiber"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader"
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import helvetiker from "three/examples/fonts/helvetiker_regular.typeface.json"

const Text3DIntroduction4 = () => {
    //Greeting
  var greeting;
  const current_time = new Date();
  const morning_start = new Date().setHours(6, 0, 0);     // 6:00 AM
  const afternoon_start = new Date().setHours(12, 0, 0); // 12:00 PM
  const evening_start = new Date().setHours(18, 0, 0);   // 6:00 PM

  if (current_time >= morning_start && current_time < afternoon_start) {
  greeting = "Good morning,";
  } else if (current_time >= afternoon_start && current_time < evening_start) {
  greeting = "Good afternoon,";
  } else {
  greeting = "Good evening,";
  }

  extend({ TextGeometry })
  
  const helvetikerRegular = new FontLoader().parse(helvetiker)
  
  const textOptionsH1 = {
    font: helvetikerRegular,
    size: 0.8,
    height: 0.2,
  }
  const textOptions = {
    font: helvetikerRegular,
    size: 1.5,
    height: 0.2,
    fontWeight: 10000
  }


  const handleLinkedInClick = () => {
    // Redirect to the LinkedIn homepage
    window.location.href = 'https://www.linkedin.com/in/ericlai3351/';
  };
  const handleGitHubClick = () => {
    // Redirect to the GitHub homepage
    window.location.href = 'https://github.com/paranoidFrappe';
  }
  
  return(
    <>
    <mesh position={[10.5, 5, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={["< h1 >", textOptionsH1]}/>
      <meshLambertMaterial attach='material' color={'rgb(150, 200, 250)'}/>
    </mesh>
    <mesh position={[10.5, 3, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={[`  Let's Connect !`, textOptions]}/>
      <meshLambertMaterial attach='material' color={'rgb(255, 255, 210)'}/>
    </mesh>

    <mesh position={[10.5, 1.8, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={["< / h1 >", textOptionsH1]}/>
      <meshLambertMaterial attach='material' color={'rgb(150, 200, 250)'}/>
    </mesh>
    <group onClick={handleLinkedInClick} >
      <mesh position={[10.5, 0.5, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
        <textGeometry attach='geometry' args={['< a href = "', textOptionsH1]}/>
        <meshLambertMaterial attach='material' color={'rgb(150, 200, 250)'}/>
      </mesh>
      <mesh
          position={[1.25, -0.2, -0.41]} // Slightly lower than the LinkedIn text
          rotation={[0, 0, 0]}
          scale={[-1, 1, 1]}
        >
          <boxGeometry args={[8, 2, 0.001]} />
          <meshBasicMaterial
            color="rgb(10, 50, 50)" opacity={0} transparent depthWrite={false}
          />
      </mesh>
      <mesh position={[6, -1, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
        <textGeometry attach='geometry' args={[`  LinkedIn`, textOptions]}/>
        <meshLambertMaterial attach='material' color={'rgb(100, 200, 255)'}/>
      </mesh>
      <mesh
          position={[-6.15, -1.95, -0.41]} // Slightly lower than the LinkedIn text
          rotation={[0, 0, 0]}
          scale={[-1, 1, 1]}
        >
          <boxGeometry args={[4.5, 1, 0.001]} />
          <meshBasicMaterial
            color="rgb(10, 50, 50)" opacity={0} transparent depthWrite={false}
          />
      </mesh>
      <mesh position={[-3, -2.3, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
        <textGeometry attach='geometry' args={['">click me!</a>', textOptionsH1]}/>
        <meshLambertMaterial attach='material' color={'rgb(150, 200, 250)'}/>
      </mesh>
    </group>
    <group onClick={handleGitHubClick} >
    <mesh position={[10.5, -3.7, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={['< a href = "', textOptionsH1]}/>
      <meshLambertMaterial attach='material' color={'rgb(150, 200, 250)'}/>
    </mesh>
    <mesh
        position={[1.1, -4.45, -0.41]} // Slightly lower than the LinkedIn text
        rotation={[0, 0, 0]}
        scale={[-1, 1, 1]}
        >
        <boxGeometry args={[6.5, 2, 0.001]} />
        <meshBasicMaterial
          color="rgb(10, 50, 50)" opacity={0} transparent depthWrite={false}
        />
    </mesh>
    <mesh position={[5.8, -5.2, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={[`   GitHub`, textOptions]}/>
      <meshLambertMaterial attach='material' color={'rgb(100, 255, 200)'}/>
    </mesh>
    <mesh
          position={[-6.15, -5.5, -0.41]} // Slightly lower than the LinkedIn text
          rotation={[0, 0, 0]}
          scale={[-1, 1, 1]}
        >
          <boxGeometry args={[4.5, 1, 0.001]} />
          <meshBasicMaterial
            color="rgb(10, 50, 50)" opacity={0} transparent depthWrite={false}
          />
      </mesh>
    <mesh position={[-3, -5.9, -0.6]} rotation={[0, 0, 0]} scale={[-1, 1, 1]}>
      <textGeometry attach='geometry' args={['">click me!</a>', textOptionsH1]}/>
      <meshLambertMaterial attach='material' color={'rgb(150, 200, 250)'}/>
    </mesh>
    </group>
    </>
  )

}

export default Text3DIntroduction4;